$(function() {
  var scrollTop = 0;

  $('.js-filters-open').on('click', function(e) {
    e.preventDefault();

    $('.js-filters-panel').addClass('c-filters__filters--opened');
    scrollTop = $window.scrollTop();
    $body.addClass('no-scroll').css('top', -scrollTop + 'px');
  });

  $('.js-filters-close').on('click', function(e) {
    e.preventDefault();

    $body.removeClass('no-scroll').css('top', '');
    $window.scrollTop(scrollTop);

    $('.js-filters-panel').removeClass('c-filters__filters--opened');
  })
});
